import api from "@/util/api";
import subjectSort from "@/util/subjectSort";

const state = {
  worklists: [],
  worklist_date: null,
  worklist_loading: null
}

const actions = {
  loadWorklist (context, data) {
    let details = []
    context.commit('setLoading', data)
    api.get('/api/worklist/details', {params: data})
      .then(response => {
        details = response.data.map(item => {

          item.clients = item.clients.map(ic => ({
            ...ic,
            ...context.state.clients.find(c => c.id.toString() === ic.client_id)
          }))

          item.no_homework = false

          item.clients.forEach(cl => {
            cl.details = cl.details
              .map(s => {
                let subject = context.state.subjects.find(sj => sj.id.toString() === s.school_subject_id)
                if (!subject)
                  item.no_homework = true
                return {...s, subject, sort_num: subject ? subject.sort_num : 0}
              })
              .filter(s => s.subject)
              .sort(subjectSort)
          })
          return {...item}
        })
        context.commit('setWorklist', details)
      })
  }
}

const mutations = {
  setLoading (state, data) {
    state.worklist_loading = data
  },
  setWorklist (state, data) {
    state.worklist_loading = false
    state.worklists = data
  }
}

export default {state, actions, mutations}