import Vue from 'vue'
import date from './date'
import dateISO from './dateISO'
import datetime from './datetime'
import time from './time'
import ago from './ago'
import money from './money'
import float from './float'
import class_text from "@/filters/class_text";

Vue.filter('date', date)
Vue.filter('dateISO', dateISO)
Vue.filter('datetime', datetime)
Vue.filter('time', time)
Vue.filter('ago', ago)
Vue.filter('money', money)
Vue.filter('float', float)
Vue.filter('class_text', class_text)
