import users from '@/modules/users'
import clients from '@/modules/clients'
import school_classes from '@/modules/school_classes'
import contracts from '@/modules/contracts'
import contragents from '@/modules/contragents'
import special_days from '@/modules/special_days'
import worklist from '@/modules/worklist'
import crm from '@/modules/crm'
import $user from '@/util/user'
import preferences from "@/modules/preferences";

const state = {
  document_templates: [
    // { name: 'Договор', slug: 'contract' },
    {name: 'Формуляр за записване', slug: 'sign-in'},
    // {name: 'Декларация 1+3', slug: 'declaration_1_3'},
    {name: 'Декларация 1', slug: 'declaration1'},
    {name: 'Декларация 2', slug: 'declaration2'},
    {name: 'Декларация 3', slug: 'declaration3'},
    {name: 'Декларация парк', slug: 'declaration_outside'},
    {name: 'Информирано съгласие', slug: 'consent'},
  ],
  /*eslint no-undef: "error"*/
  user: window.user,
  dateFormat: 'dd.MM.yyyy г.',
  timeFormat: 'HH:mm',
  dateTimeFormat: 'dd.MM.yyyy г. HH:mm',
  drawer: true,
  // eslint-disable-next-line
  application_name,

  payment_types: window.payment_types,
  schedule_shifts: window.schedule_shifts,

  ...users.state,
  ...clients.state,
  ...school_classes.state,
  ...contracts.state,
  ...contragents.state,
  ...special_days.state,
  ...worklist.state,
  ...crm.state,
  ...preferences.state
}

const actions = {
  ...users.actions,
  ...clients.actions,
  ...school_classes.actions,
  ...contracts.actions,
  ...contragents.actions,
  ...special_days.actions,
  ...worklist.actions,
  ...crm.actions,
  ...preferences.actions
}

const mutations = {
  ...users.mutations,
  ...clients.mutations,
  ...school_classes.mutations,
  ...contracts.mutations,
  ...contragents.mutations,
  ...special_days.mutations,
  ...worklist.mutations,
  ...crm.mutations,
  ...preferences.mutations,
  setDrawer (state, value = null) {
    state.drawer = value
  }
}

const getters = {
  ...clients.getters,
  ...school_classes.getters,
  ...preferences.getters,
  menus () {
    return [
      {icon: 'fas fa-users', title: 'Ученици', access: $user.isAdmin, link: '/clients'},
      // {icon: 'fas fa-graduation-cap', title: 'Класове', access: $user.isAdmin, link: '/classes'},
      {icon: 'far fa-address-book', title: 'Присъствия', access: !$user.isStudent, link: '/presence'},
      {icon: 'fas fa-cash-register', title: 'Плащания', access: $user.isAdmin, link: '/payments'},
      // {icon: 'fas fa-user-tie', title: 'Контрагенти', access: $user.isAdmin, link: '/contragents'},
      {icon: 'far fa-calendar-alt', title: 'График', access: !$user.isStudent, link: '/calendar'},
      {icon: 'far fa-sun', title: 'Лято', access: $user.isAdmin, link: '/summer'},
      //<!--      students only-->
      {
        icon: 'fas fa-chalkboard-teacher',
        title: 'Online',
        access: $user.isProbation || $user.isEmployee || $user.isAdmin,
        link: '/homework'
      },
      {
        icon: 'far fa-address-book',
        title: 'Присъствия',
        access: $user.account && !($user.isProbation || $user.isEmployee),
        link: '/attendance'
      },
      {icon: 'far fa-file-excel', title: 'Справки', access: $user.isAdmin, link: '/reports'},
      {
        icon: 'fas fa-tasks',
        title: 'Работен лист',
        access: $user.isProbation || $user.isEmployee || $user.isAdmin,
        link: '/worklist'
      },
      {
        icon: 'fas fa-tasks',
        title: 'Работен лист',
        access: $user.account && !($user.isProbation || $user.isEmployee),
        link: '/client-worklist'
      },
      // {icon: 'fas fa-tasks', title: 'Помощник', access: $user.isAdmin, link: '/open-ai'},
    ]
  },
}

export default {state, actions, mutations, getters}
