import api from "@/util/api";
import {saveItem} from "@/util/helpers";

const state = {
  crm_leads: []
}

const actions = {
  getLeads (context) {
    return api.get('/api/crm')
      .then(response => {
        context.commit('setCrmLeads', response.data)
      })
  },
  saveLead(context, lead) {
    return saveItem('crm', lead).then(response => {
      context.commit('updateLead', response.data)
    })
  }
}

const mutations = {
  setCrmLeads (state, data) {
    state.crm_leads = data
  },
  updateLead(state, data) {
    let c = state.crm_leads.find(x => x.id === data.id)
    c ? Object.assign(c, data) : state.crm_leads.push(data)
  }
}

export default {state, actions, mutations}
