import api from "@/util/api";

const state = {
  preferences: window.preferences,
}

const actions = {
  savePreference (context, params) {
    return api.post('/api/preference', [params]).then(response => {
      context.commit('setPreferences', response.data)
    })
  }
}

const mutations = {
  setPreferences (state, value) {
    state.preferences = value
  }
}

const getters = {
  PREFERENCE_VISIBLE_SHIFTS (state) {
    return state.preferences.VISIBLE_SHIFTS.value.split(',').map(i => parseInt(i))
  }
}

export default {state, actions, mutations, getters}
