import Vue from 'vue'
import store from '../store'
import router from "@/router";

Vue.user = Vue.prototype.$user = {
  get id () {
    return store.state.user.id
  },
  get email () {
    return store.state.user.email
  },
  get name () {
    return store.state.user.name || store.state.user.email
  },
  get isAdmin () {
    return store.state.user.role === 'Administrator'
  },
  get isStudent () {
    return store.state.user.role === 'Student'
  },
  get isEmployee () {
    return store.state.user.role === 'Employee'
  },
  get isProbation () {
    return store.state.user.role === 'Probation'
  },
  get company () {
    return store.state.user.company
  },
  get role () {
    return store.state.user.role
  },
  get account () {
    if(!this.isEmployee && store.state.user.client_id)
      return store.state.clients.find(x => x.id === store.state.user.client_id)
    return null;
  },
  get client_id () {
    return store.state.user.client_id
  }
}

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.roles && !to.meta.roles.includes(store.state.user.role)) {
    // console.warn(to.meta.roles)
    next('/')
  } else
    next()
})

export default Vue.user
