import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/modules/module'

Vue.use(Vuex)

const store = new Vuex.Store({
  ...modules
})

store.commit('setSpecialDays', window.special_days)

export default store