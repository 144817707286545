import api from "@/util/api";
import Vue from 'vue'

export function parseError (error) {
  if (error && error.response && error.response.data.message)
    return error.response.data.message
  // return error.response.data[0].message
  return error.toString()
}

export function saveItem (model, data) {
  let url = data.id ? '/api/' + model + '/update' : '/api/' + model + '/create'
  let method = data.id ? 'put' : 'post'
  return api[method](url, data, {params: {id: data.id}}).then((response) => {
    return response
  }).catch((error) => {
    Vue.toasted.show(error, {type: 'error'})
  })
}

export function deleteItem (model, data) {
  let url = '/api/' + model + '/delete'
  let method = 'delete'
  return api[method](url, {params: {id: data.id}}).then((response) => {
    return response
  }).catch((error) => {
    Vue.toasted.show(error, {type: 'error'})
  })
}

export function parse_pagination (response) {
  return {
    currentPage: parseInt(response.headers['x-pagination-current-page']),
    pageCount: parseInt(response.headers['x-pagination-page-count']),
    perPage: parseInt(response.headers['x-pagination-per-page']),
    totalCount: parseInt(response.headers['x-pagination-total-count'])
  }
}

export function lightOrDark (color) {

  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {

    return 'light';
  } else {

    return 'dark';
  }
}

export function toFloat(float) {
  let result = parseFloat(float)
  return isNaN(result) ? 0 : result
}
