import api from "@/util/api";

const state = {
  clients: window.clients,
  locations: window.locations,
  client_sort: 'school_class',
}

const actions = {
  listClients (context, params) {
    let sort = context.state.client_sort
    if (params && params.sort) {
      sort = params.sort
      context.commit('setClientSort', sort)
    }
    if (!params)
      params = {sort}
    else
      params.sort = sort
    return api.get('/api/client', {params}).then((response) => {
      context.commit('setClients', response.data)
    })
  },
  updateClient (context, data) {
    api.get('/api/client/view', { params : { id: data.id }}).then(response => {
      context.commit('updateClient', response.data)
    })
  },
  saveClient (context, data) {
    return api[data.id ? 'put' : 'post']('/api/client/' + (data.id ? 'update' : 'create') + '?id=' + data.id, data)
      .then((response) => {
        if(response.data.client_group === 'student')
          context.commit('updateClient', response.data)
        else
          context.dispatch('getContragents')
      })
  },
  savePresence (context, data) {
    return api[data.id ? 'put' : 'post']('/api/presence/' + (data.id ? 'update' : 'create') + '?id=' + data.id, data).then((response) => {
      // context.commit('updateClient', response.data)
      return response.data
    })
  }
}

const mutations = {
  setClientSort (state, value) {
    state.client_sort = value
  },
  setClients (state, data) {
    state.clients = data
  },
  updateClient (state, data) {
    let c = state.clients.find(x => x.id === data.id)
    c ? Object.assign(c, data) : state.clients.push(data)
  }
}

const getters = {
  employees (state) {
    return state.clients.filter(x => x.client_group === 'employee')
  },
  students (state) {
    return state.clients.filter(x => x.client_group === 'student')
  },
  contragents (state) {
    return state.clients.filter(x => x.client_group !== 'student')
  }
}

export default {state, actions, mutations, getters}