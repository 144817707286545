import store from "@/store";

export default (value) => {
  let result = value.class_name
  const year = store.state.school_year.school_year
  if (value.teacher_name && value.add_on.toString() === "0")
    result += ' / ' + value.teacher_name
  else
    result += ' / ' + value.shift_name

  if(year !== value.school_year)
    result += `(${value.school_year})`

  return result
}
