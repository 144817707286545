import api from "@/util/api"
import Vue from 'vue'
import {saveItem} from "@/util/helpers";

const state = {
  contract_types: window.contract_types,
  contract_type_labels: {
    '1m': 'месечно',
    '6m': 'срочно',
    '1y': 'годишно',
  }
}

const actions = {
  saveContractTypesGroups (context, data) {
    return api.post('/api/contract-type/save-group', data)
      .then(response => {
        response.data.forEach(type => context.commit('updateContractType', type))
      })
  },
  saveContract (context, data) {
    // return api[data.id ? 'put' : 'post']('/api/contracts/' + (data.id ? 'update' : 'create') + '?id=' + data.id, data)
    return saveItem('contracts', data)
      .then(response => {
        // context.commit('updateContract', response.data)
        context.dispatch('updateClient', {id: data.client_id})
        return response.data
      })
  },
  cancelContract (context, contract) {
    Vue.$bus.$emit('ConfirmModal', {
      confirmation: 'Желаете ли да прекратите д-ра ?',
      callback: (result) => {
        result && api.post('/api/contracts/cancel-contract', null, {params: {id: contract.id}})
          .then(response => {
            context.commit('updateContract', response.data)
          })

      }
    })
  },
  completeContract (context, contract) {
    Vue.$bus.$emit('ConfirmModal', {
      confirmation: 'Желаете ли да маркирате д-ра за изпълнен ?',
      callback: (result) => {
        result && api.post('/api/contracts/complete-contract', null, {params: {id: contract.id}})
          .then(response => {
            context.commit('updateContract', response.data)
          })

      }
    })
  },
  editContractPrice (context, params) {
    api.post('/api/contracts/change-payment', params.type, {params: {contract_id: params.contract.id}})
      .then(response => {
        context.commit('updateContract', response.data)
      })
  }
}

const mutations = {
  updateContractType (state, data) {
    let old = state.contract_types.find(x => x.id === data.id)
    old ? Object.assign(old, data) : state.contract_types.push(data)
  },
  updateContract (state, data) {
    let client = state.clients.find(x => x.id === data.client_id)
    if (client) {
      let c = client.contracts.find(x => x.id === data.id)
      c ? Object.assign(c, data) : client.contracts.push(data)
    }
  }
}

export default {actions, mutations, state}
