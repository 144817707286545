import format from 'date-fns/format'

import bgLocale from 'date-fns/locale/bg'

import store from '@/store'

export default (value, date_format) => {
  if(value === null)
    return '';
  let _date_format = date_format ? date_format : store.state.timeFormat
  return format(new Date(value * 1000), _date_format, {locale: bgLocale})
}