import api from "@/util/api";
import {parseError, saveItem} from "@/util/helpers";
import Vue from "vue";

const state = {
  school_classes: window.school_classes,
  current_year: window.school_year?.school_year,
  school_year: window.school_year,
  school_years: window.school_years,
  subjects: window.subjects,
  // eslint-disable-next-line no-undef
  shifts: window.shifts
}

const actions = {
  saveStudentSchedule (context, data) {
    return api.post('/api/school-class/student-schedule', data.items,
      {params: {shift_id: data.shift_id, client_id: data.client_id}}).then(response => {
      response.data.forEach(sc => context.commit('updateSchoolClass', sc))
      return response.data
    }).catch(err => {
      Vue.toasted.show(parseError(err), {type: 'error'})
    })
  },
  saveSchoolClass (context, data) {
    // context.commit('updateSchoolClass', data)
    return api[data.id ? 'put' : 'post']('/api/school-class/' + (data.id ? 'update' : 'create') + '?id=' + data.id, data)
      .then(response => {
        context.commit('updateSchoolClass', response.data)
        return response.data
      })
  },
  listSchoolClasses (context) {
    return api.get('/api/school-class').then((response) => {
      context.commit('setSchoolClasses', response.data)
    })
  },
  getSubjects (context) {
    return api.get('/api/school-subject').then(response => {
      context.commit('setSubjects', response.data)
    })
  },
  saveSubject (context, data) {
    saveItem('school-subject', data).then(() => {
      context.dispatch('getSubjects')
    })
  }
}

const mutations = {
  setSubjects (state, data) {
    state.subjects = data
  },
  setSchoolClasses (state, data) {
    state.school_classes = data
  },
  updateSchoolClass (state, data) {
    let c = state.school_classes.find(x => x.id === data.id)
    c ? Object.assign(c, data) : state.school_classes.push(data)
  }
}

const getters = {
  extra_classes (state) {
    return state.school_classes.filter(x => x.add_on)
  },
  current_classes (state) {
    return state.school_classes.filter(x => x.school_year === state.current_year)
  },
  current_school_classes (state, getters) {
    return getters.current_classes.filter(x => !x.addon)
  },
  class_groups (state, getters) {
    return getters.current_classes
      .reduce((agg, el) => {
        return !agg.includes(el.class_group && el.class_group.toString()) ? agg.concat(el.class_group.toString()) : agg
      }, []).sort()
  },
  summer_class (state, getters) {
    let summer = getters.extra_classes.find(x => x.shift_id === 4 && x.school_year === state.current_year)
    if(!summer)
      summer = getters.extra_classes.find(x => x.shift_id === 4 && x.school_year === state.current_year - 1)
    return summer
  },
}

export default {actions, mutations, state, getters}
