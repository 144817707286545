import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/Home')
    },
    {
      path: '/summer',
      name: 'summer',
      component: () => import('@/pages/SummerPage.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/pages/admin/Users'),
      meta: {roles: ['Administrator']}
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/pages/Clients'),
      meta: {title: 'Ученици', roles: ['Administrator', 'Employee']}
    },
    {
      path: '/classes',
      name: 'classes',
      component: () => import('@/pages/SchoolClasses'),
      meta: {title: 'Класове', roles: ['Administrator']}
    },
    {
      path: '/presence/:date?',
      component: () => import('@/pages/PresenceForm'),
      name: 'presence',
      meta: {title: 'Присъствия', roles: ['Administrator', 'Employee', 'Probation']}
    },
    {
      path: '/payments',
      component: () => import('@/pages/PaymentsPage'),
      name: 'payments',
      meta: {title: 'Плащания', roles: ['Administrator']}
    },
    {
      path: '/contragents',
      component: () => import('@/pages/admin/ContragentsPage'),
      name: 'contragents',
      meta: {title: 'Контрагенти', roles: ['Administrator']}
    },
    {
      path: '/calendar',
      component: () => import('@/pages/CalendarPage'),
      name: 'calendar',
      meta: {title: 'Смени'}
    },
    {
      path: '/homework',
      // eslint-disable-next-line no-undef
      component: () => ['Administrator', 'Employee', 'Probation'].includes(user.role) ? import('@/pages/homework/HomeworkManager') : import('@/pages/Homework'),
      name: 'homework',
      meta: {title: 'Домашни'},
    },
    {
      name: 'check-homework',
      path: '/homework/edit/:filename',
      component: () => import('@/pages/homework/HomeworkFabric'),
    },
    {
      name: 'preview-homework',
      path: '/homework/preview/:file',
      component: () => import('@/pages/homework/HomeworkPreview'),
    },
    {
      name: 'attendance',
      path: '/attendance',
      component: () => import('@/pages/AttendancePage'),
    },
    {
      name: 'client-worklist',
      path: '/client-worklist',
      component: () => import('@/pages/ClientWorklistPage'),
    },
    {
      name: 'orders',
      path: '/orders',
      component: () => import('@/pages/OrdersPage'),
    }, {
      name: 'reports',
      path: '/reports',
      component: () => import('@/pages/ReportsIndex'),
      meta: {title: 'Справки', roles: ['Administrator']}
    },
    {
      name: 'PresenceReport',
      path: '/reports/presence',
      component: () => import('@/pages/reports/PresenceReport'),
      meta: {title: 'Справка присъствия', roles: ['Administrator']}
    },
    {
      name: 'OrderReport',
      path: '/reports/orders',
      component: () => import('@/pages/reports/OrderReport'),
      meta: {title: 'Справка поръчки', roles: ['Administrator']}
    },
    {
      name: 'InvoiceReport',
      path: '/reports/invoice',
      component: () => import('@/pages/reports/InvoiceReport'),
      meta: {title: 'Справка фактури', roles: ['Administrator']}
    },
    {
      name: 'ForInvoiceReport',
      path: '/reports/for-invoice',
      component: () => import('@/pages/reports/ForInvoiceReport'),
      meta: {title: 'Справка неиздадени фактури', roles: ['Administrator']}
    },
    {
      name: 'WorklistPage',
      path: '/worklist',
      component: () => import('@/pages/WorklistPage'),
      meta: {title: 'Работен лист', roles: ['Administrator', 'Employee', 'Probation']}
    },
    {
      name: 'AdminPage',
      path: '/administration',
      component: () => import('@/pages/AdminPage'),
      meta: {title: 'Администрация', roles: ['Administrator']}
    },
    {
      name: 'OpenAI',
      path: '/open-ai',
      component: () => import('@/pages/OpenAIPage.vue'),
      meta: {title: 'Помощник' }
    },
    {
      name: 'Requests',
      path: '/requests',
      component: () => import('@/pages/CrmPage.vue'),
      meta: {title: 'Заявки' }
    }
  ]
})

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' - ' + window.application_name
  }
})

export default router
