import api from '@/util/api'
const state = {
}

const actions = {
  getContragents(context) {
    api.get('/api/client').then(response => {
      context.commit('setContragents', response.data)
    })
  }
}

const mutations = {
  setContragents(state, data) {
    state.contragents = data.filter(x => x.client_group !== 'student')
  }
}

export default {state, actions, mutations}