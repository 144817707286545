<template>
    <flat-pickr
            class="form-control datepicker"
            v-model="timestamp"
            :config="config"
            placeholder="yyyy-mm-dd"
            v-if="!readonly"
    ></flat-pickr>
    <div v-else class="form-control form-control-static" readonly>{{ text_only }}</div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'
import locale from 'flatpickr/dist/l10n/bg.js';
import parse from 'date-fns/parseISO'

export default {
  name: "DatePicker",
  components: {flatPickr},
  props: {
    value: [Number, String],
    as_timestamp: {
      type: Boolean,
      default: true
    },
    readonly: Boolean,
    enableTime: Boolean
  },
  data () {
    return {
      config: {
        altInput: true,
        altFormat: 'd.m.Y г.',
        locale: locale['bg'],
        disableMobile: true,
        enableTime: this.enableTime
      }
    }
  },
  computed: {
    text_only () {
      if (!this.timestamp)
        return this.timestamp;
      return this.$options.filters.date(this.timestamp / 1000)
    },
    timestamp: {
      get () {
        if (this.value === null)
          return null
        return Number.isInteger(this.value) ? this.value * 1000 : parse(this.value).getTime()
      },
      set (value) {
        let dt = new Date(value)
        if (dt.getTime() !== this.value * 1000) {
          let emit_value = dt.getTime()
          if (!this.as_timestamp) {
            emit_value = dt.getFullYear() + '-'
            emit_value += (dt.getMonth() + 1) > 9 ? (dt.getMonth() + 1) : '0' + (dt.getMonth() + 1)
            emit_value += '-'
            emit_value += dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()
          } else
            emit_value /= 1000
          if (!value)
            emit_value = null
          this.$emit('input', emit_value);
        }
      }
    }
  },
}
</script>

<style lang="scss">
.form-control.datepicker[readonly] {
  background-color: white;
  cursor: pointer;
}
</style>
