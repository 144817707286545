import {saveItem} from "@/util/helpers";
import api from "@/util/api";

const state = {
  special_days: [],
  special_types: [
    {id: 1, label: 'Ковид19'},
    {id: 2, label: 'ваканция'},
    {id: 3, label: 'почивен'},
  ]
}

const actions = {
  getSpecialDays (context) {
    api.get('/api/special-days').then(response => {
      context.commit('setSpecialDays', response.data)
    })
  },
  saveSpecialDay (context, data) {
    return saveItem('special-days', data).then(() => {
      // context.commit('setSpecialDays', response.data)
      context.dispatch('getSpecialDays')
    })
  }
}

const mutations = {
  setSpecialDays (state, data) {
    if(!data)
      return false
    state.special_days = data.map(x => ({...x, type: state.special_types.find(t => x.special_type === t.id)}))
  }
}

export default {state, actions, mutations}
