import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import 'vuetify/dist/vuetify.min.css'
import * as lib from 'vuetify/lib/components'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  components: lib,
})

const opts = {
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.purple,
        secondary: colors.deepOrange,
        accent: colors.blue.lighten5,
        error: colors.red,
        // success: colors.amber.accent1
      },
      dark: {
        // primary: colors.deepPurple.darken1,
        'text-color': colors.deepPurple.darken1
      }
    }
  }
}

//   primary: '#1976D2',
//   secondary: '#424242',
//   accent: '#82B1FF',
//   error: '#FF5252',
//   info: '#2196F3',
//   success: '#4CAF50',
//   warning: '#FFC107',

export default new Vuetify(opts)
