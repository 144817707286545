import Vue from 'vue'

Vue.component('modal', require('@innologica/vue-stackable-modal').default)
Vue.component('dropdown', require('@innologica/vue-dropdown-menu').default)

//Toast messages
import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 3000,
  // theme: 'inoreader',
  type: 'info'
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
import "tippy.js/themes/light.css";

import VueNativeNotification from 'vue-native-notification'
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

Vue.directive('visible', function(el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});